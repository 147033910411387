import request from '@/utils/request';

export const getEmailsRequest = (params) => request.get('/emails', {params: params});

export const createEmailRequest = (data) => request.post('/emails/', data);

export const getEmailRequest = (id) => request.get(`/emails/${id}`);

export const updateEmailRequest = (id, data) => request.put(`/emails/${id}`, data);

export const deleteEmailRequest = (id) => request.delete(`/emails/${id}`);
