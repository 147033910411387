import request from '@/utils/request';

export const getServicesRequest = (params, responseType = 'json') => request.get('/services', { responseType, params });

export const createServiceRequest = (data) => request.post('/services/', data);

export const getServiceRequest = (id) => request.get(`/services/${id}`);

export const updateServiceRequest = (id, data) => request.post(`/services/${id}`, data);

export const deleteServiceRequest = (id, data) => request.post(`/services/${id}`, data);

export const addServiceUserRequest = (data) => request.post('/services/add-service-user', data);
export const updateServiceUserRequest = (id, data) => request.put(`/services/update-service-user/${id}`, data);
export const deleteServiceUserRequest = (id) => request.delete(`/services/delete-service-user/${id}`);
