import Vue from 'vue'
import moment from 'moment';
import BootstrapVue from 'bootstrap-vue'
import VueRouter from 'vue-router'
import vco from "v-click-outside"
import router from './router/index'
import VueSweetalert2 from 'vue-sweetalert2'
import Multiselect from 'vue-multiselect'
import Auth from "@/plugins/auth";
import keycloakAuth from "@/utils/keycloakAuth";
import '@/plugins/global.js'
import "@/design/index.scss";

import store from '@/state/store'

import App from './App.vue'

Vue.prototype.$moment = moment;

Vue.use(VueRouter)
Vue.use(vco)
Vue.config.productionTip = false

Vue.use(BootstrapVue)
Vue.use(VueSweetalert2)
Vue.use(Auth)
Vue.component('multiselect', Multiselect)

keycloakAuth({Vue, store}, () => {
      new Vue({
        el: "#app",
        router,
        store,
        render: (h) => h(App),
      })
    }
)
