import Vue from 'vue';
import {
  getServicesRequest,
  getServiceRequest,
  createServiceRequest,
  updateServiceRequest,
  deleteServiceRequest,
  addServiceUserRequest,
  updateServiceUserRequest,
  deleteServiceUserRequest,
} from '@/api/services';
import { downloadFile } from '@/utils/files';
import { showSuccessToast, showErrorToast } from '@/utils/toasts';

export const state = {
  list: [],
  service: null,
  loading: false,
  totalItems: 0
}

export const mutations = {
  SET_LIST(state, list) {
    state.list = list;
  },
  SET_SERVICE(state, item) {
    state.service = item;
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
  ADD_TO_LIST(state, items) {
    state.list.push(items);
  },
  UPDATE_ITEM(state, item) {
    const index = state.list.findIndex(({ id }) => id === item.id)
    if (index + 1) {
      Vue.set(state.list, index, item);
    }
  },
  DELETE_ITEM(state, itemId) {
    const index = state.list.findIndex(({ id }) => id === itemId);
    if (index + 1) {
      state.list.splice(index, 1);
    }
  },
  SET_TOTAL_RECORDS(state, count) {
    state.totalItems = count;
  },
}

export const actions = {
  async getServices({ commit }, params) {
    try {
      commit('SET_LIST', []);
      commit('SET_LOADING', true);
      const { data } = await getServicesRequest(params);
      if (data) {
        if (params?.per_page) {
          commit('SET_LIST', data.data);
          commit('SET_TOTAL_RECORDS', data.total);
        } else {
          commit('SET_LIST', data);
          commit('SET_TOTAL_RECORDS', data.length);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getService({ commit }, {id: id}) {
    try {
      commit('SET_SERVICE', null);
      commit('SET_LOADING', true);
      const { data } = await getServiceRequest(id);
      if (data) {
        commit('SET_SERVICE', data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async createService({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const response = await createServiceRequest(payload);
      if (response.code === 200) {
        commit('ADD_TO_LIST', response.data);
        showSuccessToast('Service created successfully');
      }else{
        if (response.code === 422) {
          showErrorToast(response.data);
        }else{
          showErrorToast(response.message);
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateService({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      const response = await updateServiceRequest(id, data);
      if (response.code === 200) {
        commit('UPDATE_ITEM', response);
        showSuccessToast('Item updated successfully');
      }else{
        if (response.code === 422) {
          response.data.forEach((el) => {
            showErrorToast(el);
          })
        }else{
          showErrorToast(response.message);
        }
      }
      return response;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deleteService({ commit }, { data, id }) {
    try {
      commit('SET_LOADING', true);
      await deleteServiceRequest(id, data);
      commit('DELETE_ITEM', id);
      showSuccessToast('Item deleted successfully')
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },



  async addServiceUser({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const res = await addServiceUserRequest(payload);
      if (res.code === 500) {
        showErrorToast('Data already has been added', 500);
      }else{
        showSuccessToast('Service user created successfully');
      }
      return res;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateServiceUser({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      const response = await updateServiceUserRequest(id, data);
      if (response.code === 200) {
        showSuccessToast('Item updated successfully');
      }else{
        if (response.code === 422) {
          response.data.forEach((el) => {
            showErrorToast(el);
          })
        }else{
          showErrorToast(response.message);
        }
      }
      return response;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deleteServiceUser({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      await deleteServiceUserRequest(payload);
      showSuccessToast('Item deleted successfully')
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async downloadCSVServices({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const response = await getServicesRequest({ ...payload, csv: 1 }, 'blob');
      const blob = new Blob([response], { type: response.type })
      downloadFile(blob, 'services.csv');
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

export const getters = {
  servicesOptions(state) {
    return [
      { value: null, text: 'Choose Service' },
      ...state.list
        .map(({ id, title }) => ({ value: id, text: title }))
    ];
  },
}
