import request from '@/utils/request';

export const getRolesRequest = () => request.get('/roles');

export const createRoleRequest = (data) => request.post('/roles/', data);

export const getRoleRequest = (id) => request.get(`/roles/${id}`);

export const updateRoleRequest = (id, data) => request.post(`/roles/${id}`, data);

export const deleteRoleRequest = (id, data) => request.post(`/roles/${id}`, data);
