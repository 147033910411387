import Vue from 'vue';
import {
  getRolesRequest,
  createRoleRequest,
  updateRoleRequest,
  deleteRoleRequest
} from '@/api/roles';

import {showErrorToast, showSuccessToast} from '@/utils/toasts';

export const state = {
  list: [],
  loading: false,
  totalItems: 0
}

export const mutations = {
  SET_LIST(state, list) {
    state.list = list;
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
  ADD_TO_LIST(state, items) {
    state.list.push(items);
  },
  UPDATE_ITEM(state, item) {
    const index = state.list.findIndex(({ id }) => id === item.id)
    if (index + 1) {
      Vue.set(state.list, index, item);
    }
  },
  DELETE_ITEM(state, itemId) {
    const index = state.list.findIndex(({ id }) => id === itemId);
    if (index + 1) {
      state.list.splice(index, 1);
    }
  },
  SET_TOTAL_RECORDS(state, count) {
    state.totalItems = count;
  },
}

export const actions = {
  async getRoles({ commit }) {
    try {
      commit('SET_LIST', []);
      commit('SET_LOADING', true);
      const { data } = await getRolesRequest();
      if (data) {
        commit('SET_LIST', data);
        // commit('SET_TOTAL_RECORDS', data.total || 0);
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async createRole({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const { data, code} = await createRoleRequest(payload);
      if (code === 200) {
        commit('ADD_TO_LIST', data);
        showSuccessToast('Role created successfully');
        return data;
      } else if (code === 422) {
        data.forEach((el) => {
          showErrorToast(el);
        })
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateRole({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      const { data: response, code } = await updateRoleRequest(id, data);
      if (response && code === 200) {
        commit('UPDATE_ITEM', response);
        showSuccessToast('Item updated successfully');
      } else if (code === 422) {
        response.forEach((el) => {
          showErrorToast(el);
        })
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deleteRole({ commit }, { data, id }) {
    try {
      commit('SET_LOADING', true);
      await deleteRoleRequest(id, data);
      commit('DELETE_ITEM', id);
      showSuccessToast('Item deleted successfully')
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

export const getters = {
  rolesOptions(state) {
    return [
      { value: null, text: 'Choose Role' },
      ...state.list
        .map(({ id, title }) => ({ value: id, text: title }))
    ];
  },
}
