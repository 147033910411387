import Vue from 'vue';
import {
  getEmailsRequest,
  createEmailRequest,
  updateEmailRequest,
  deleteEmailRequest
} from '@/api/emails';

import { showSuccessToast } from '@/utils/toasts';
import {showErrorToast} from "../../utils/toasts";

export const state = {
  list: [],
  loading: false,
  totalItems: 0
}

export const mutations = {
  SET_LIST(state, list) {
    state.list = list;
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
  ADD_TO_LIST(state, items) {
    state.list.push(items);
  },
  UPDATE_ITEM(state, item) {
    const index = state.list.findIndex(({ id }) => id === item.id)
    if (index + 1) {
      Vue.set(state.list, index, item);
    }
  },
  DELETE_ITEM(state, itemId) {
    const index = state.list.findIndex(({ id }) => id === itemId);
    if (index + 1) {
      state.list.splice(index, 1);
    }
  },
  SET_TOTAL_RECORDS(state, count) {
    state.totalItems = count;
  },
}

export const actions = {
  async getEmails({ commit }, params) {
    try {
      commit('SET_LIST', []);
      commit('SET_LOADING', true);
      const { data } = await getEmailsRequest(params);
      if (data) {
        commit('SET_LIST', data.data);
        commit('SET_TOTAL_RECORDS', data.total || 0);
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async createEmail({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const response = await createEmailRequest(payload);
      if (response?.code === 200 && response?.data) {
        commit('ADD_TO_LIST', response.data);
        showSuccessToast('Email created successfully');
      } else {
        showErrorToast(response?.data ?? 'Something went wrong');
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async updateEmail({ commit }, { id, data }) {
    try {
      commit('SET_LOADING', true);
      const response = await updateEmailRequest(id, data);
      if (response.code === 200) {
        commit('UPDATE_ITEM', response);
        showSuccessToast('Item updated successfully');
      }else{
        if (response.code === 422) {
          showErrorToast(response.data);
        }
      }
      return response;
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async deleteEmail({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      await deleteEmailRequest(payload);
      commit('DELETE_ITEM', payload);
      showSuccessToast('Item deleted successfully')
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

export const getters = {
  emailsOptions(state) {
    return [
      { value: null, text: 'Choose Email' },
      ...state.list
        .map(({ id, title }) => ({ value: id, text: title }))
    ];
  },
}
