import { getMeRequest } from '@/api/user';

export const state = {
  user: {
    name: '',
    photo: ''
  },
  loading: false,
}

export const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
  SET_LOADING(state, value) {
    state.loading = value;
  }
}

export const actions = {
  async getMe({ commit }) {
    try {
      commit('SET_LOADING', true);
      const {data} = await getMeRequest();
      if (data) {
        commit('SET_USER', data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  logout() {
    window.location.replace(`${process.env.VUE_APP_AUTH_REDIRECT_LINK}logout?redirect_url=${window.location.href}`);
  }
};
