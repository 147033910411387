export default [
  {
    path: '/404',
    name: '404',
    meta: {roles: ['all']},
    component: require('./views/utility/404').default,
  },
  {
    path: '*',
    redirect: '404',
  },
  {
    path: '/',
    redirect: '/services',
  },
  {
    path: '/employees',
    name: 'employees',
    meta: { authRequired: true, title: 'Employees', roles: ['admin'] },
    component: () => import('./views/employees/index')
  },
  {
    path: '/employees/:id',
    name: 'employee',
    meta: { authRequired: true, title: 'Employee', roles: ['admin'] },
    component: () => import('./views/employee/index')
  },
  {
    path: '/services',
    name: 'services',
    meta: { authRequired: true, title: 'Services', roles: ['admin'] },
    component: () => import('./views/services/index')
  },
  {
    path: '/services/:id',
    name: 'servicesShow',
    meta: { authRequired: true, title: 'Service detail', roles: ['admin'] },
    component: () => import('./views/services/show/index')
  },
  {
    path: '/emails',
    name: 'emails',
    meta: { authRequired: true, title: 'Emails', roles: ['admin'] },
    component: () => import('./views/emails/index')
  },
  {
    path: '/notifications',
    name: 'notifications',
    meta: { authRequired: true, title: 'Notifications', roles: ['admin'] },
    component: () => import('./views/notifications/index')
  },
  {
    path: '/pages/404',
    name: 'Error-404',
    meta: { authRequired: true, roles: ['all']},
    component: () => import('./views/utility/404')
  },
  {
    path: '/pages/500',
    name: 'Error-500',
    meta: { authRequired: true, roles: ['all'] },
    component: () => import('./views/utility/500')
  },
  {
    path: '/settings/console-commands',
    name: 'ConsoleCommands',
    meta: { title: 'Console Commands', roles: ['admin'] },
    component: () => import('./views/settings/commands')
  },
]
