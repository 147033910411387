import {
  getEmployeeRequest,
  getEmployeesRequest,
  getEmployeesServicesRequest,
  notifyEmployeeRequest,
  getActiveEmployeesRequest
} from '@/api/employees';
import {showErrorToast, showSuccessToast} from "../../utils/toasts";
export const state = {
  list: [],
  totalRecordsCount: 0,
  activeEmployees: [],
  employeesServices: [],
  loading: false,
  employee: null,
  totalEmployeeServices: 0
}

export const mutations = {
  SET_LIST(state, list) {
    state.list = list;
  },
  SET_RECORDS_COUNT(state, count) {
    state.totalRecordsCount = count;
  },
  SET_ACTIVE_EMPLOYEES(state, employees) {
    state.activeEmployees = employees;
  },
  SET_EMPLOYEES_SERVICES(state, services) {
    state.employeesServices = services;
  },
  SET_LOADING(state, value) {
    state.loading = value
  },
  SET_TOTAL_EMPLOYEES_SERVICES_RECORDS(state, count) {
    state.totalEmployeeServices = count;
  },
  SET_EMPLOYEE(state, value) {
    state.employee = value;
  }
}

export const actions = {
  async getEmployees({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const response = await getEmployeesRequest(payload);
      if (response?.data) {
        if (payload?.per_page) {
          commit('SET_LIST', response?.data?.data ?? []);
          commit('SET_RECORDS_COUNT', response?.data?.total ?? 0);
        } else {
          commit('SET_LIST', response.data);
        }
      }
    } catch (e) {
      console.error(e);
      commit('SET_LIST', []);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async fetchEmployee({ commit }, payload) {
    try {
      const response = await getEmployeeRequest(payload);
      if (response?.code === 200 && response?.data) {
        commit('SET_EMPLOYEE', response.data);
      }
    } catch(error) {
      console.error(error);
    }
  },
  async getActiveEmployees({ commit }) {
    try {
      commit('SET_ACTIVE_EMPLOYEES', []);
      commit('SET_LOADING', true);
      const { data } = await getActiveEmployeesRequest();
      if (data) {
        commit('SET_ACTIVE_EMPLOYEES', data);
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async getEmployeesServices({ commit }, params) {
    try {
      commit('SET_EMPLOYEES_SERVICES', []);
      commit('SET_LOADING', true);
      const { data } = await getEmployeesServicesRequest(params);
      if (data) {
        commit('SET_EMPLOYEES_SERVICES', data.data);
        commit('SET_TOTAL_EMPLOYEES_SERVICES_RECORDS', data.total);
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
  async notifyEmployee({ commit }, payload) {
    try {
      commit('SET_LOADING', true);
      const res = await notifyEmployeeRequest(payload);
      if (res.code === 200) {
        showSuccessToast('Employee notified successfully');
      } else {
        showErrorToast(res?.message ?? 'Something went wrong');
      }
    } catch (e) {
      console.error(e);
    } finally {
      commit('SET_LOADING', false);
    }
  },
};

export const getters = {
  employeesOptions(state) {
    return [
      { value: null, text: 'Choose Employee' },
      ...state.activeEmployees
        .map(({ uid, name }) => ({ value: uid, text: name }))
    ];
  },
}
