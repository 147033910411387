import axios from 'axios';
import Vue from "vue";
import { showErrorToast } from '@/utils/toasts';

export const API_PATH = process.env.VUE_APP_API_PATH ?  process.env.VUE_APP_API_PATH : 'https://accounts.mybimiboo.com/api';

axios.defaults.crossDomain = true;
axios.defaults.withCredentials = true;

const service = axios.create({ baseURL: API_PATH });
service.interceptors.request.use(
    function (config) {
        const token = Vue.$keycloak.token;
        config.headers["Authorization"] = `Bearer ${token}`;
        return config;
    },
    function (error) {
        return Promise.reject(error);
    }
);
service.interceptors.response.use(
  (res) => {
    const { data, config } = res;
    if (config.responseType === 'blob') {
      return data;
    }
    if (data.code) {
      if (data.code && data.code === 200) {
        return data;
      }
      else if (data.code === 422) {
          return { code: data.code, data: data.data, message: data.message };
      }else if(data.code !== 200){
          let message = data.message;
          if(data?.data?.length > 0){
              for (const dataKey in data.data) {
                  message += '<br> - ' + data.data[dataKey];
              }
          }
          showErrorToast(message);
          throw data.message;
      }

      showErrorToast(data.message);
      return { code: data.code, data: null, message: data.message };
    } else {
      showErrorToast(data.message);
      return data;
    }
  },
  (error) => {
    showErrorToast(error.message);
  }
);

export default service;
